<template>
  <div class="info-container" v-if="imgFile">
    <transition
        appear-class="enter"
        leave-active-class="leave"
        enter-active-class="enter"
        mode="out-in"
      >
      <div class="lib-right-cont" :key="imgFile._id">
        <video v-if="imgFile.isVideo" controls style="height: 100%">
          <source :src="imgFile.url" />
        </video>
        <img v-else
          :src="imgFile.url"
          class="lib-right-oneImage"
          :style="
            imgFile.w > imgFile.h
              ? 'width: 100%;'
              : 'height: 100%;'
          "
        />
      </div>
    </transition>
    <div class="lib-right-name-cont">
      <div class="fields">
        <span>Title:</span>
        <span>{{ imgFile.name }}</span>
      </div>
      <div class="fields">
        <span>Type:</span>
        <span>{{ imgFile.mimeType }}</span>
      </div>
      <div class="fields">
        <span>Size:</span>
        <span>{{ sizeFormat(imgFile.size) }}</span>
      </div>
      <div class="fields" v-if="imgFile.w && imgFile.h">
        <span>Dimensions:</span>
        <span>{{ imgFile.w }} x {{ imgFile.h }}</span>
      </div>
      <div class="fields">
        <span>Updated:</span>
        <span>{{ dateFormat(imgFile.mtimeMs) }}</span>
      </div>
      <div class="mt-3 text-center">
        <button ref="copyUrlBtn" type="button" class="btn-sm btn-secondary" data-bs-placement="bottom" @click="copyUrl">
          <i class="fa fa-copy" /> Copy url
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Tooltip from 'bootstrap/js/dist/tooltip'
import dateFormat from 'date-format'

export default {
  props: ['imgFile'],
  watch: {
    imgFile() {
      if (this._copyTooltip) {
        this._copyTooltip.dispose()
        this._copyTooltip = null
      }
    }
  },
  methods: {
    dateFormat(time) {
      return dateFormat('dd.MM.yyyy hh:mm:ss', new Date(time))
    },
    sizeFormat(size) {
      const sizes = {Mb: 1024*1024, Kb: 1024}
      for (const s in sizes) {
        if (size > sizes[s]) {
          return (Math.round(size/sizes[s] * 100) / 100) + ' ' + s
        }
      }
      return size + ' b'
    },
    copyUrl() {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(location.origin + this.imgFile.url)
          .then(() => this.showCopyTooltip('URL successfully copied'))
          .catch(err => this.showCopyTooltip(`Error: ${err.message}`, true))
      } else {
        this.showCopyTooltip('Browser does not support clipboard API', true)
      }
    },
    showCopyTooltip(text, isError) {
      if (this._copyTooltip) {
        this._copyTooltip.dispose()
      }
      this._copyTooltip = new Tooltip(this.$refs.copyUrlBtn, {
        title: isError ? `<span class="text-danger">${text}</span>` : text,
        trigger: 'manual',
        html: true,
      })
      this._copyTooltip.show()
      clearTimeout(this._copyTooltipTm)
      this._copyTooltipTm = setTimeout(() => {
        this._copyTooltip && this._copyTooltip.hide()
      }, 3000)
    },
  }
}
</script>

<style lang="scss" scoped>
@keyframes leave {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 1;
    transform: translateX(20px);
  }
}

@keyframes enter {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.enter {
  animation: enter 0.3s linear;
}

.leave {
  animation: leave 0.3s linear;
}

.lib-right {
  flex-direction: column;
  display: flex;
  width: 25%;
  border-left: 1px solid #000;
}

.lib-rigth-header {
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid black;

  & > h3 {
    margin: 0;
    padding: 0;
    margin: 0 10px;
    color: $fontColor;
    font-weight: normal;
  }
}

.lib-right-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}

.lib-right-cont {
  background-color: #131313;
  background-image: url("~@/assets/img/loader.svg");
  background-repeat: no-repeat;
  background-position: center;
  outline: 1px solid gray;
  margin: 10px auto;
  height: 150px;
  width: 250px;
  text-align: center;
}

.lib-right-oneImage[lazy="loaded"] {
  border: 5px solid burlywood;
  width: 100%;
  height: 100%;
}

.lib-right-oneImage {
  margin: auto;
  height: 100%;
}

.lib-right-name-cont {
  display: flex;
  flex-direction: column;
  margin: 10px auto;
  padding: 0 5px;

  & > .fields {
    margin: 10px 0;
    display: flex;
    justify-content: flex-start;

    & > span:first-of-type {
      color: rgb(226, 223, 223);
      font-weight: 600;
      display: block;
    }

    & > span:last-of-type {
      margin-left: 10px;
      color: $fontColor;
      font-weight: 400;
      display: block;
    }
  }
}

.lib-right-message {
  width: 100%;
  display: flex;
  padding: 40px 10px;
  align-items: center;
  justify-content: center;

  & > p {
    color: $fontColor;
  }
}
</style>
