<template>
  <div class="login-form-outer d-flex justify-content-center align-items-center">
    <div class="login-form p-4">
      <form @submit.prevent="doLogin">
        Username
        <input type="text" class="form-control mb-2" v-model="username" />
        Password
        <input type="password" class="form-control mb-3" v-model="password" />
        <button class="w-100 btn btn-primary mb-3" :disabled="isDisabled">Login</button>
        <div class="text-center text-danger">
          {{ loginError }}
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      inProgress: false,
      loginError: '',
    }
  },
  computed: {
    isDisabled() {
      return this.inProgress || !this.username || !this.password
    }
  },
  methods: {
    doLogin() {
      this.inProgress = true
      this.$auth.login({data: {username: this.username, password: this.password}})
        .catch(err => {
          const res = err.response || {}
          if (res.status == 401) {
            this.loginError = 'Unvalid username or password'
          } else {
            this.loginError = err.message || 'Unknown error'
          }
        })
        .then(() => this.inProgress = false)
    }
  }
}
</script>

<style lang="scss" scoped>
.login-form-outer {
  height: calc(100vh - #{$heightHeader});
}
.login-form {
  background-color: #202020;
  width: 100%;
  max-width: 28rem;
}
</style>
