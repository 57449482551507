import BaseModel from './BaseModel'
import { v4 as uuid4 } from 'uuid'

export default class Slide extends BaseModel {
  static entity = 'slides'
  static primaryKey = '_id'

  static fields() {
    return {
      _id: this.uid(() => uuid4()),
      url: this.string(''),
      type: this.string(''),
      displayId: this.attr(null),
      position: this.number(0),
      interactive: this.boolean(),
      loop: this.boolean(),
    }
  }
}
