import { Model } from '@vuex-orm/core'

export default class Base extends Model {
  static fetchAll() {
    return this.api().get(this.entity)
  }

  static getModified() {
    function clearServiceFields(ent) {
      Object.keys(ent).forEach(key => {
        if (key[0] === '$') {
          delete ent[key]
        }
      })
      return ent
    }
    const created = this.query().where('$isNew', true).get().map(clearServiceFields)
    const updated = this.query().where(rec => {
      return rec.$isDirty && !rec.$isNew && !rec.$trashed()
    }).get().map(clearServiceFields)
    const deleted = this.query().onlyTrashed().get().map(clearServiceFields)

    if (created.length || updated.length || deleted.length) {
      return {created, updated, deleted}
    }

    return null
  }

  static saveAll() {
    const modified = this.getModified()
    if (modified) {
      return this.api().post(this.entity, modified, {save: false})
    }
    return Promise.resolve()
  }

  get stateCss() {
    if (this.$trashed()) {
      return 'fa-trash'
    }
    if (this.$isNew) {
      return 'fa-plus'
    }
    if (this.$isDirty) {
      return 'fa-pencil'
    }
    return null
  }
}
