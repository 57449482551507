<template>
  <div class="ds-left">
    <router-link v-for="item in menu" :key="item.to" :to="{ name: item.to }" class="menu-item">
      <i class="fa" :class="item.iconClass" />
      <i class="active-mark fa fa-angle-double-right"/>
      {{ item.title }}
    </router-link>
  </div>
</template>

<script>
export default {
  computed: {
    menu() {
      return this.$router.options.routes[1].children
        .filter(route => route.meta && route.meta.title)
        .map(route => ({to: route.name, title: route.meta.title, iconClass: route.meta.iconClass}))
    }
  }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 1px !important;
  height: 8px;
  background-color: $baseColor;
}

.ds-left {
  overflow-x: hidden;
  display: flex;
  min-width: 300px;
  border-right: 1px solid #000;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;

  .menu-item {
    padding: 10px 10px 10px 5px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    color: $fontColor;
    box-sizing: border-box;

    &:hover {
      color: $textHover;
    }
    &.router-link-active {
      color: $textActive;
      .active-mark {
        display: inline-block;
      }
    }
    & > i {
      width: 40px;
      padding: 10px;
    }
    .active-mark {
      width: 15px;
      padding: 0;
      display: none;
    }
  }
}

.ds-left-footer {
  margin-top: auto;
  display: flex;
  padding: 0 20px;
  min-height: 50px;
  border-top: 1px solid black;
  align-items: center;
}

.addlinks {
  margin-left: 20px;
  display: flex;
  justify-content: flex-start;
  font-size: 15px;
}

.mainLink {
  text-decoration: none;
  color: $fontColor;
  text-align-last: left;
}

.ds-left-add-device {
  width: 30px;
  height: 30px;
  outline: none;
  background-color: $buttonColor;
  color: $buttonTextColor;

  &:hover {
    color: $textHover;
  }
  &:active {
    transform: scale(0.9);
  }
}
</style>
