<template>
  <div class="content-header">
    <h2><slot/></h2>
  </div>
</template>

<style lang="scss" scoped>
.content-header {
  width: 100%;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid black;
  background-color: $baseColor;

  & > h2 {
    display: flex;
    margin: 0;
    width: 100%;
    font-size: 1.2rem;
    font-weight: 300;
    justify-content: center;
    color: $fontColor;
  }
  & > .trash {
    display: flex;
    margin: 0 20px 0 0;
    border: none;
    background-color: transparent;
    outline: none;
    padding: 5px;
    color: white;

    & > i {
      font-size: 30px;
      color: rgb(214, 91, 91);
      margin: 0 20px 0 20px;
    }

    &:hover > i {
      color: rgb(196, 52, 52);
    }

    &:active > i {
      color: rgb(109, 33, 33);
    }
  }

  & > .disabled {
    margin: 0 20px 0 0;
    background-color: transparent;
    outline: none;
    padding: 5px;
    color: white;
    cursor: not-allowed;

    & > i {
      font-size: 30px;
      color: rgb(133, 115, 115);
      margin: 0 20px 0 20px;
    }

    &:hover > i {
      color: rgb(133, 115, 115);
    }

    &:active > i {
      color: rgb(133, 115, 115);
    }
  }
}
</style>
