<template>
  <ds-header/>
  <router-view/>
</template>

<script>
import DsHeader from "@/components/DsHeader";

export default {
  components: {
    DsHeader,
  },
};
</script>>

<style lang="scss">
@import "@/assets/scss/app";

#app {
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
