<template>
  <div class="w-100">
    <content-header>
      <div class="flex-fill text-center align-self-center">Projects</div>
      <div class="ms-auto">
        <button class="btn btn-sm btn-secondary" :disabled="reloading" @click="reload">
          <i class="fa fa-refresh"></i>
        </button>
        <button class="btn btn-sm btn-primary ms-1 me-2" :disabled="saving || !isModified" @click="save">
          <i class="fa fa-save"></i>
        </button>
      </div>
    </content-header>

    <div class="content">
      <div class="d-flex h-100 align-items-center justify-content-center" v-if="reloading">
        <img src="@/assets/img/loader.svg" />
      </div>
      <template v-else>
        <div class="d-flex">
          <input type="text" class="form-control flex-fill" v-model="newProject.title"/>
          <button class="btn btn-primary ms-2" :disabled="!newProject.title" @click="createNew">
            <i class="fa fa-plus" />
          </button>
        </div>
        <hr/>
        <div v-for="project in projects" :key="project._id" class="entity-row d-flex align-items-center mt-3">
          <input type="text" class="form-control flex-fill" :value="project.title" :disabled="project.$trashed()" @change="change($event, project._id)"/>
          <i class="state fa ms-2" :class="project.stateCss"/>

          <button class="btn btn-warning ms-2" @click="restore(project._id)" v-if="project.$trashed()">
            <i class="fa fa-undo" />
          </button>
          <button class="btn btn-danger ms-2" @click="remove(project)" v-else>
            <i class="fa fa-trash" />
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ContentHeader from '@/components/Library/ContentHeader'
import ProjectModel from '@/store/models/Project'

export default {
  components: { ContentHeader },
  data() {
    return {
      newProject: {},
      reloading: false,
      saving: false,
    }
  },
  computed: {
    projects() {
      return ProjectModel.query().withTrashed().orderBy('title').get()
    },
    isModified() {
      return !!ProjectModel.getModified()
    },
  },
  created() {
    this.reload()
  },
  methods: {
    reload() {
      this.reloading = true
      ProjectModel.deleteAll()
      ProjectModel.fetchAll()
        .finally(() => this.reloading = false)
    },
    change(e, projectId) {
      ProjectModel.update({
        where: projectId,
        data: {
          title: e.target.value
        }
      })
    },
    remove(project) {
      if (project.$isNew) {
        project.$delete()
      } else {
        project.$softDelete()
      }
    },
    restore(projectId) {
      const project = ProjectModel.find(projectId)
      if (project) {
        project.$restore()
      }
    },
    createNew() {
      ProjectModel.insert({data: Object.assign({}, this.newProject, {$isNew: true})})
      this.newProject = {}
    },
    save() {
      this.saving = true
      ProjectModel.saveAll()
        .then(() => this.reload())
        .finally(() => this.saving = false)
    },
  }
}
</script>
