import { createStore } from 'vuex'
import orm from '@/plugins/vuex-orm'
import localSettings from './local-settings'

export default createStore({
  state: {
    dirId: '',
    localSettings,
  },
  mutations: {
    changeDirId(state, dirId) {
      state.dirId = dirId
    },
    saveLocalSettings(state, settings) {
      Object.assign(state.localSettings, settings)
      localStorage.localSettings = JSON.stringify(state.localSettings)
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [orm]
})
