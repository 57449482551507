import BaseModel from './BaseModel'

export default class Display extends BaseModel {
  static entity = 'displays'
  static primaryKey = '_id'

  static fields() {
    return {
      _id: this.attr(),
      title: this.string(''),
      projectId: this.attr(null),
    }
  }
}
