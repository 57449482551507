<template>
  <div class="w-100">
    <content-header>
      <div class="flex-fill text-center align-self-center">Displays</div>
      <div class="ms-auto d-flex">
        <select class="form-select form-select-sm" style="max-width: 300px" v-model="selectedProject">
          <option value="">All projects</option>
          <option :value="project._id" v-for="project in projects" :key="project._id">
            {{ project.title }}
          </option>
        </select>
        <button class="btn btn-sm btn-secondary ms-1" :disabled="reloading" @click="reload">
          <i class="fa fa-refresh"></i>
        </button>
        <button class="btn btn-sm btn-primary ms-1 me-2" :disabled="saving || !isModified" @click="save">
          <i class="fa fa-save"></i>
        </button>
      </div>
    </content-header>

    <div class="content">
      <div class="d-flex h-100 align-items-center justify-content-center" v-if="reloading">
        <img src="@/assets/img/loader.svg" />
      </div>
      <template v-else>
        <div v-if="selectedProject">
          <div class="d-flex">
            <input type="text" class="form-control me-2" style="width: 200px" placeholder="ID" v-model="newDisplay._id"/>
            <input type="text" class="form-control flex-fill" placeholder="Title" v-model="newDisplay.title"/>
            <button class="btn btn-primary ms-2" :disabled="!canCreate" @click="createNew">
              <i class="fa fa-plus" />
            </button>
          </div>
          <hr/>
        </div>

        <div v-for="display in displays" :key="display._id" class="entity-row d-flex align-items-center mt-3">
          <input type="text" class="form-control me-2" style="width: 200px" :value="display._id" readonly/>
          <input type="text" class="form-control flex-fill" :value="display.title" :disabled="display.$trashed()" @change="change($event, display._id)"/>
          <i class="state fa ms-2" :class="display.stateCss"/>

          <button class="btn btn-warning ms-2" @click="restore(display._id)" v-if="display.$trashed()">
            <i class="fa fa-undo" />
          </button>
          <button class="btn btn-danger ms-2" @click="remove(display)" v-else>
            <i class="fa fa-trash" />
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ContentHeader from '@/components/Library/ContentHeader'
import ProjectModel from '@/store/models/Project'
import DisplayModel from '@/store/models/Display'

export default {
  components: { ContentHeader },
  data() {
    return {
      selectedProject: '',
      newDisplay: {},
      reloading: false,
      saving: false,
    }
  },
  computed: {
    projects() {
      return ProjectModel.query().orderBy('title').get()
    },
    displays() {
      const qb = DisplayModel.query().withTrashed()
      if (this.selectedProject) {
        qb.where('projectId', this.selectedProject)
      } else {
        qb.orderBy('projectId')
      }
      return qb.get()
    },
    isModified() {
      return !!DisplayModel.getModified()
    },
    canCreate() {
      return this.selectedProject
        && this.newDisplay._id
        && this.newDisplay.title
        && /^[-_0-9a-z]+$/.test(this.newDisplay._id)
        && !DisplayModel.find(this.$auth.user().username + '/' + this.newDisplay._id)
    },
  },
  created() {
    if (!ProjectModel.exists()) {
      ProjectModel.fetchAll()
    }
    this.reload()
  },
  methods: {
    reload() {
      this.reloading = true
      DisplayModel.deleteAll()
      DisplayModel.fetchAll()
        .finally(() => this.reloading = false)
    },
    save() {
      this.saving = true
      DisplayModel.saveAll()
        .then(() => this.reload())
        .finally(() => this.saving = false)
    },
    createNew() {
      DisplayModel.insert({data: Object.assign({}, this.newDisplay, {projectId: this.selectedProject, $isNew: true})})
      this.newDisplay = {}
    },
    change(e, id) {
      DisplayModel.update({
        where: id,
        data: {
          title: e.target.value
        }
      })
    },
    remove(ent) {
      if (ent.$isNew) {
        ent.$delete()
      } else {
        ent.$softDelete()
      }
    },
    restore(id) {
      const ent = DisplayModel.find(id)
      if (ent) {
        ent.$restore()
      }
    },
  }
}
</script>
