<template>
  <div class="lib-container">
    <left-side-bar />

    <div class="lib-body">
      <div class="lib-content">
        <router-view v-slot="{ Component }">
          <transition
            enter-active-class="sl-enter"
            leave-active-class="sl-leave"
            mode="out-in"
          >
            <component :is="Component" @change-sidebar="rightSidebar = $event" />
          </transition>
        </router-view>
      </div>
    </div>

    <div class="lib-right" :class="{active: rightSidebar.component || $route.meta.hasSidebar}">
      <component :is="rightSidebar.component" v-bind="rightSidebar.attrs" />
    </div>
  </div>
</template>

<script>
import LeftSideBar from '@/components/Library/LeftSideBar'

export default {
  components: { LeftSideBar },
  data() {
    return {
      rightSidebar: {
        component: null,
        attrs: {}
      }
    }
  },
  watch: {
    $route() {
      this.rightSidebar.component = null
    }
  }
};
</script>

<style lang="scss" scoped>
.lib-container {
  display: flex;
  width: 100%;
  height: calc(100vh - #{$heightHeader});
  background-color: $baseColor;

  .lib-body {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    align-items: flex-start;
    overflow: hidden;

    .lib-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      height: 100%;
      background-color: #131313;
      color: #ccc;
    }
  }

  .lib-right {
    width: 0;
    padding: 25px;
    border-left: 1px solid black;
    overflow: hidden;
    transition: .1s;

    &.active {
      width: 500px;
    }
  }
}
</style>
