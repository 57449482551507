import { Model } from '@vuex-orm/core'

export default class File extends Model {
  static entity = 'files'
  static primaryKey = '_id'

  static fields() {
    return {
      _id: this.attr(null),
      parentId: this.attr(''),
      name: this.attr(''),
      isDir: this.boolean(false),
      mtimeMs: this.number(0),
      url: this.string(''),
      size: this.number(0),
      mimeType: this.string(''),
      w: this.number(0),
      h: this.number(0),
    }
  }

  static fetchByDirId(dirId = '') {
    return this.api().get(`/files/${dirId}`)
  }

  get isVideo() {
    const fileType = this.mimeType ? this.mimeType.split('/')[0] : ''
    return fileType === 'video'
  }
}
