import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '@/views/Login.vue'
import Library from '@/views/Library.vue'
import LibraryProjects from '@/views/Library/Projects.vue'
import LibraryDisplays from '@/views/Library/Displays.vue'
import LibrarySlides from '@/views/Library/Slides.vue'
import LibraryFiles from '@/views/Library/Files.vue'

const routes = [
  { path: '/login', name: 'Login', component: Login, meta: {auth: false} },
  { path: '/', redirect: {name: 'LibrarySlides'}, name: 'Library', component: Library, meta: {auth: true}, children: [
    { path: '/projects', name: 'LibraryProjects', component: LibraryProjects, meta: {title: 'Projects', iconClass: 'fa-files-o'} },
    { path: '/displays', name: 'LibraryDisplays', component: LibraryDisplays, meta: {title: 'Displays',iconClass: 'fa-television'} },
    { path: '/slides', name: 'LibrarySlides', component: LibrarySlides, meta: {title: 'Slides',iconClass: 'fa-sliders'} },
    { path: '/files', name: 'LibraryFiles', component: LibraryFiles, meta: {title: 'Files', iconClass: 'fa-file-image-o', hasSidebar: true} },
  ]},
  { path: '/:pathMatch(.*)*', redirect: {name: 'Library'} }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
