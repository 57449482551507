<template>
  <div class="w-100">
    <content-header>
      <div class="flex-fill text-center align-self-center">Slides</div>
      <div class="ms-auto d-flex">
        <a class="btn btn-sm btn-primary" :href="clientUrl" target="_blank" v-if="selectedDisplay">
          <i class="fa fa-link"></i>
        </a>
        <select class="form-select form-select-sm ms-1" style="max-width: 300px" v-model="selectedDisplay">
          <option value="">Deleted displays</option>
          <option :value="display._id" v-for="display in displays" :key="display._id" :disabled="display.isDisabled">
            {{ display.title }}
          </option>
        </select>
        <button class="btn btn-sm btn-secondary ms-1" :disabled="reloading" @click="reload">
          <i class="fa fa-refresh"></i>
        </button>
        <button class="btn btn-sm btn-primary ms-1 me-2" :disabled="saving || !isModified" @click="save">
          <i class="fa fa-save"></i>
        </button>
      </div>
    </content-header>

    <div class="content">
      <div class="d-flex h-100 align-items-center justify-content-center" v-if="reloading">
        <img src="@/assets/img/loader.svg" />
      </div>
      <template v-else>
        <div v-if="selectedDisplay">
          <div class="entity-row d-flex" >
            <div class="preview">
              <img :src="newSlide.url" v-if="newSlide.type === 'image'"/>
              <video :src="newSlide.url" v-else-if="newSlide.type === 'video'" controls/>
              <iframe :src="newSlide.url" v-else />
            </div>
            <div class="flex-fill d-flex flex-column ms-2">
              <input type="text" class="form-control" v-model="newSlide.url" />
              <div class="mt-2">
                <select class="form-select d-inline-block me-3" style="width: 150px" v-model="newSlide.type">
                  <option>image</option>
                  <option>video</option>
                  <option>webpage</option>
                </select>
                <div class="form-check d-inline-block"  v-if="newSlide.type === 'video'">
                  <input class="form-check-input" type="checkbox" id="loop-new" v-model="newSlide.loop">
                  <label class="form-check-label" for="loop-new">
                    Loop
                  </label>
                </div>
                <div class="form-check d-inline-block"  v-if="newSlide.type === 'webpage'">
                  <input class="form-check-input" type="checkbox" id="interactive-new" v-model="newSlide.interactive">
                  <label class="form-check-label" for="interactive-new">
                    Interactive
                  </label>
                </div>
              </div>
              <div class="mt-auto text-end">
                <button class="btn btn-primary" @click="createNew" :disabled="!newSlide.url || !newSlide.type">
                  <i class="fa fa-plus" />
                </button>
              </div>
            </div>
          </div>
          <hr/>
        </div>


        <div v-for="(slide, i) in slides" :key="slide._id" class="entity-row d-flex">
          <div class="preview">
            <img :src="slide.url" v-if="slide.type === 'image'"/>
            <video :src="slide.url" v-else-if="slide.type === 'video'" controls/>
            <iframe :src="slide.url" v-else />
          </div>
          <div class="flex-fill d-flex flex-column ms-2">
            <input type="text" class="form-control" v-model="slide.url" @change="change(slide)" />
            <div class="mt-2">
              <select class="form-select d-inline-block me-3" style="width: 150px" v-model="slide.type" @change="change(slide)">
                <option>image</option>
                <option>video</option>
                <option>webpage</option>
              </select>
              <div class="form-check d-inline-block"  v-if="slide.type === 'video'">
                <input class="form-check-input" type="checkbox" :id="`loop-${slide._id}`" v-model="slide.loop" @change="change(slide)">
                <label class="form-check-label" :for="`loop-${slide._id}`">
                  Loop
                </label>
              </div>
              <div class="form-check d-inline-block"  v-if="slide.type === 'webpage'">
                <input class="form-check-input" type="checkbox" :id="`interactive-${slide._id}`" v-model="slide.interactive" @change="change(slide)">
                <label class="form-check-label" :for="`interactive-${slide._id}`">
                  Interactive
                </label>
              </div>
            </div>
            <div class="mt-auto text-end">
              <button class="btn btn-warning ms-2" @click="restore(slide._id)" v-if="slide.$trashed()">
                <i class="fa fa-undo" />
              </button>
              <button class="btn btn-danger ms-2" @click="remove(slide)" v-else>
                <i class="fa fa-trash" />
              </button>
            </div>
          </div>
          <div class="d-flex flex-column justify-content-between ms-2" v-if="selectedDisplay">
            <i class="fa fa-caret-up text-center p-2" style="cursor: pointer;" v-if="i > 0" @click="moveUp(i)" />
            <i v-else class="p-2" />
            <i class="state fa" :class="slide.stateCss" />
            <i class="fa fa-caret-down text-center p-2" style="cursor: pointer;" v-if="i < slides.length - 1" @click="moveDown(i)" />
            <i v-else class="p-2" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ContentHeader from '@/components/Library/ContentHeader'
import ProjectModel from '@/store/models/Project'
import DisplayModel from '@/store/models/Display'
import SlideModel from '@/store/models/Slide'

export default {
  components: { ContentHeader },
  data() {
    return {
      selectedDisplay: '',
      newSlide: {},
      reloading: false,
      saving: false,
    }
  },
  computed: {
    displays() {
      const result = []
      ProjectModel.all().forEach(project => {
        const displays = DisplayModel.query().where('projectId', project._id).orderBy('title').get()
        if (displays.length) {
          result.push({
            _id: 'project-' + project._id,
            title: `----- ${project.title} -----`,
            isDisabled: true,
          })
          result.push(...displays)
        }
      })

      return result
    },
    slides() {
      const qb = SlideModel.query().withTrashed()
      if (this.selectedDisplay) {
        qb.where('displayId', this.selectedDisplay)
      } else {
        const displayIds = this.displays.map(d => d._id)
        qb.where('displayId', val => !displayIds.includes(val))
      }
      qb.orderBy('position')
      return qb.get()
    },
    isModified() {
      return !!SlideModel.getModified()
    },
    clientUrl() {
      return process.env.VUE_APP_CLIENT_URL + '/' + this.selectedDisplay
    },
  },
  created() {
    if (!ProjectModel.exists()) {
      ProjectModel.fetchAll()
    }
    if (!DisplayModel.exists()) {
      DisplayModel.fetchAll()
    }
    this.reload()
  },
  methods: {
    reload() {
      this.reloading = true
      SlideModel.deleteAll()
      SlideModel.fetchAll()
        .finally(() => this.reloading = false)
    },
    save() {
      this.saving = true
      SlideModel.saveAll()
        .then(() => this.reload())
        .finally(() => this.saving = false)
    },
    createNew() {
      this.newSlide.position = this.slides.length ? Math.max(...this.slides.map(s => s.position)) + 1 : 0
      SlideModel.insert({data: Object.assign({}, this.newSlide, {displayId: this.selectedDisplay, $isNew: true})})
      this.newSlide = {}
    },
    moveUp(idx) {
      if (idx <= 0) {
        return
      }
      this.moveSlide(this.slides[idx - 1], this.slides[idx])
    },
    moveDown(idx) {
      if (idx >= this.slides.length - 1) {
        return
      }
      this.moveSlide(this.slides[idx + 1], this.slides[idx])
    },
    moveSlide(sn, sa) {
      const newPos = sn.position
      sn.position = sa.position
      sa.position = newPos
      sn.$save()
      sa.$save()
    },
    change(ent) {
      ent.$save()
    },
    remove(ent) {
      if (ent.$isNew) {
        ent.$delete()
      } else {
        ent.$softDelete()
      }
    },
    restore(id) {
      const ent = SlideModel.find(id)
      if (ent) {
        ent.$restore()
      }
    },
    copyDisplayId() {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.selectedDisplay)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.entity-row {
  background-color: $baseColor;
  padding: 10px;
  margin: 5px 0;
  .preview {
    width: 225px;
    height: 150px;
    border: 1px solid #ccc;
    > * {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
