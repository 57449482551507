import BaseModel from './BaseModel'
import { v4 as uuid4 } from 'uuid'

export default class Project extends BaseModel {
  static entity = 'projects'
  static primaryKey = '_id'

  static fields() {
    return {
      _id: this.uid(() => uuid4()),
      title: this.string(''),
    }
  }
}
