import http from './http'
import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'
import VuexORMSoftDelete from '@vuex-orm/plugin-soft-delete'
import VuexORMisDirtyPlugin from '@vuex-orm/plugin-change-flags'
import File from '@/store/models/File'
import Project from '@/store/models/Project'
import Display from '@/store/models/Display'
import Slide from '@/store/models/Slide'

VuexORM.use(VuexORMAxios, { axios: http })
VuexORM.use(VuexORMisDirtyPlugin)
VuexORM.use(VuexORMSoftDelete, {
  key: '$deletedAt'
})

const database = new VuexORM.Database()

database.register(File)
database.register(Project)
database.register(Display)
database.register(Slide)

export default VuexORM.install(database)
