import http from './http'
import router from '@/router'
import { createAuth } from '@websanova/vue-auth/src/v3.js'
import driverAuth from '@websanova/vue-auth/src/drivers/auth/bearer.js'
import driverHttp from '@websanova/vue-auth/src/drivers/http/axios.1.x.js'
import driverRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js'

export default (app) => {
  app.use(createAuth({
    plugins: {
      http,
      router,
    },
    drivers: {
      http: driverHttp,
      router: driverRouter,
      auth: driverAuth,
    },
    options: {
      loginData: {staySignedIn: false},
      logoutData: {redirect: {name: 'Login'}},
      refreshData: {interval: 20},
    }
  }))
}
