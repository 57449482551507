<template>
  <div class="w-100">
    <content-header>Files</content-header>

    <div class="breadcrumbs">
      <i class="fa fa-home path-home" @click="chDir()" />
      <div class="path-item" v-for="item in path" :key="item._id">
        <span class="sep">&gt;</span>
        <div class="path-item-name" @click="chDir(item._id)">{{ item.name }}</div>
      </div>
    </div>

    <div class="content">
      <div class="file-container" v-for="file in files" :key="file._id"
        :class="{active: selectedImgId == file._id}"
        :style="{width: `${(file.h > 0 ? file.w/file.h : 1.55) * baseImgHeight * scale}px`}"
      >
        <div class="icon dir-img" v-if="file.isDir" :style="{height: `${baseImgHeight * scale}px`}" @click="chDir(file._id)"/>
        <div class="icon video-img" v-else-if="file.isVideo" :style="{height: `${baseImgHeight * scale}px`}" @click.stop="selectImg(file._id)"/>
        <div class="image-img" v-else :style="{height: `${baseImgHeight * scale}px`}">
          <img
            :src="file.url"
            @click.stop="selectImg(file._id)"
          />
        </div>
        <div class="file-name">{{ file.name }}</div>
      </div>
    </div>

    <img class="d-none" src="~@/assets/img/icons/folder-hover.svg">

    <div class="scale-container">
      <label for="scale-range">Scale :</label>
      <input id="scale-range" type="range" :value="scaleValue" @input="changeScale" />
    </div>
  </div>
</template>

<script>
import { markRaw } from 'vue'
import ContentHeader from '@/components/Library/ContentHeader'
import ImgInfo from '@/components/Library/ImgInfo'
import FileModel from '@/store/models/File'

export default {
  components: { ContentHeader },
  data() {
    return {
      baseImgHeight: 125,
      selectedImgId: null,
    }
  },
  computed: {
    files() {
      return FileModel.query()
        .where('parentId', this.dirId || 'root')
        .orderBy('isDir', 'desc')
        .orderBy('name')
        .get()
    },
    path() {
      const result = []
      const parents = []
      let parentId = this.dirId
      while (parentId) {
        if (parents.includes(parentId)) {
          break //to prevent infinity loop on bad input
        }
        parents.push(parentId)
        const dir = FileModel.find(parentId)
        if (dir) {
          parentId = dir.parentId
          result.push({_id: dir._id, name: dir.name})
        }
      }
      result.reverse()
      return result
    },
    scale() {
      return this.$store.state.localSettings.fileListScale
    },
    scaleValue() {
      return this.scale * 100 - 50
    },
    dirId() {
      return this.$store.state.dirId
    },
    selectedImg() {
      return FileModel.find(this.selectedImgId)
    }
  },
  mounted() {
    FileModel.fetchByDirId(this.dirId)
  },
  methods: {
    changeScale(e) {
      this.$store.commit('saveLocalSettings', {fileListScale: e.target.value / 100 + 0.5})
    },
    selectImg(id) {
      this.selectedImgId = id
      const sidebar = {
        component: markRaw(ImgInfo),
        attrs: id ? { imgFile: this.selectedImg } : {}
      }
      this.$emit('change-sidebar', sidebar)
    },
    chDir(id) {
      this.selectImg(null)
      this.$store.commit('changeDirId', id)
      FileModel.fetchByDirId(id)
    },
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  display: flex;
  min-height: 45px;
  align-items: center;
  font-size: 30px;
  margin: 0 30px;
  margin-top: 10px;

  .path-item {
    display: flex;
    align-items: center;

    .sep {
      margin: 0 10px;
      font-size: 20px;
    }
  }
  .path-home, .path-item-name {
    cursor: pointer;
  }
}

.content {
  height: calc(100vh - #{$heightHeader} - 155px);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.file-container {
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  &.active {
    .video-img, .image-img {
      outline: 2px solid burlywood;
    }
  }

  > .icon {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;

    &.dir-img {
      background-image: url('~@/assets/img/icons/folder.svg');
      &:hover {
        background-image: url('~@/assets/img/icons/folder-hover.svg');
      }
    }
    &.video-img {
      background-image: url('~@/assets/img/icons/video.svg');
    }
  }

  & > .image-img {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #131313;
    background-image: url("~@/assets/img/loader.svg");
    background-repeat: no-repeat;
    background-position: center;
    outline: 1px solid rgba(128, 128, 128, 0.397);

    img {
      height: 100%;
      cursor: pointer;
    }
  }
  .file-name {
    margin-top: 5px;
    width: 100%;
    color: white;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.scale-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 50px;
}
</style>
