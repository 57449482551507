<template>
  <header class="header">
    <div class="ds-logo">
      <img src="./../assets/logo.png" alt />
    </div>
    <div v-if="$auth.check()">
      {{ $auth.user().username }}
      <button class="btn btn-danger ms-2" @click="$auth.logout()">
        <i class="fa fa-sign-out"></i>
        Logout
      </button>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  background-color: $baseColor;
  height: $heightHeader;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  border-bottom: 1px solid #000;
}

.ds-logo {
  display: flex;
  transition: transform 0.3 linear;
  &:hover {
    transform: scale(1.1);
  }
  & > img {
    width: 100%;
    height: 100%;
  }
}

.ds-nav {
  margin-left: 30px;
  margin-right: auto;
  display: flex;
  & > a {
    text-decoration: none;
    margin: 0 10px;
    color: $fontColor;
  }
  & > a.active {
    color: $textActive;
  }
  & > a:hover {
    color: $textHover;
  }
}

.username {
  & > a {
    text-decoration: none;
    margin: 0 10px;
    color: white;
  }
  & > a.active {
    color: rgb(248, 148, 17);
  }
  & > a:hover {
    color: rgb(248, 71, 17);
  }
}
</style>
